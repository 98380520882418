<template>
  <div class="position-relative" style="min-height: 100vh">
    <div
      class="d-flex justify-content-center align-items-center"
      style="min-height: 100vh"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          class="spongaGif"
          src="../assets/images/Sponga_loading.gif"
          alt="loading"
        />
        <p class="loadMessage">{{ toVerscanText }}</p>
      </div>
    </div>

    <div
      class="d-flex flex-column justify-content-center align-items-center position-absolute fixed-bottom"
    >
      <div class="spongaLogo">
        <p class="powerBy">Power by</p>
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <img src="../assets/images/Sponga_Logo.png" alt="logo" width="10%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stage } from "../util/backend";
export default {
  name: "Loading",
  data() {
    return {
      timeout: null,
    };
  },
  computed: {
    toVerscanText() {
      if (localStorage.getItem("locale") === "en") {
        return "Loading VERSCAN";
      } else {
        return "กำลังกลับไปยัง VERSCAN";
      }
    },
  },
  methods: {
    goToVerscan() {
      const version = localStorage.getItem("version");
      console.log(version);
      if(version === '1'){
        this.timeout = setTimeout(() => {
          const webStage = stage();
          let link = "https://app.verscan.com";
          if (webStage === "dev") {
            link = "https://dev-app.verscan.com";
          }
          if (webStage === "uat") {
            link = "https://staging-app.verscan.com";
          }
          window.location.replace(link);
        }, 2000);
      } else {
        this.timeout = setTimeout(() => {
          const webStage = stage();
          let link = "https://www.verscan.com/";
          if (webStage === "dev") {
            link = "https://dev-verscan-nuxt.web.app/";
          }
          if (webStage === "uat") {
            link = "https://uat-verscan-nuxt.web.app/";
          }
          window.location.replace(link);
        }, 2000);
      }
    },
  },
  mounted() {
    // When the component is mounted, start the timer
    this.goToVerscan();
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout); // Clear the timeout if the component is destroyed
  },
};
</script>

<style>
.loadMessage {
  font-weight: 500;
  font-size: 100%;
  text-align: center;
  color: #013f81;
  padding-top: 20px;
}

.powerBy {
  font-size: 100%;
  text-align: center;
  margin: 0.5px;
}

.spongaGif {
  max-width: 250px;
  width: 100%;
  height: auto;
}

.spongaLogo {
  max-width: 1000px;
  width: 100%;
  height: auto;
  padding-bottom: 40px;
}
</style>
